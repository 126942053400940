.locate{
    width: 80%;
    margin: auto;
    margin-bottom: 5vw;
    margin-top: 60px;
}
.img2{
    display: none;
}

@media screen and (max-width : 426px){
    .img1{
        display: none;
    }
    .img2{
        display: block;
    }
}