
.headabove{
  background-color: #57B23C;
  z-index: 1000 ;
}
.mynav{
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: #F3F4F6;
}

@media screen and (max-width : 765px) {
    .headabove{
        display : none
    }
}